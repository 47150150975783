import React from 'react'

import s from './Prices.module.scss'
import PricesOfPackagesList from './PricesOfPackagesList'

type Props = {
  title: string
  intro: React.ReactElement
  info: React.ReactElement
  children: React.ReactNode
  hidepowerpackages?: boolean
}

const Prices = (props: Props) => {
  const { title, intro, hidepowerpackages = false, children } = props

  return (
    <>
      <div className={s.prices}>
        {!hidepowerpackages && (
          <PriceContainer title="Orkuleiðir">
            <PricesOfPackagesList />
          </PriceContainer>
        )}

        <PriceContainer title={title}>{children}</PriceContainer>
      </div>
      <div className={s.aboutPrices}>{intro}</div>
    </>
  )
}

type PriceContainerProps = {
  title: string
  children: React.ReactNode
}

const PriceContainer = (props: PriceContainerProps) => {
  const { title, children } = props

  return (
    <div className={s.prices__inner}>
      <div className={s.prices__title}>{title}</div>
      <div>
        <div className={s.prices__list}>{children}</div>
      </div>
    </div>
  )
}

export default Prices
